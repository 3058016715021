import React from 'react'

import * as headerStyles from './header.module.css'
import { Logo } from '@components/atoms/'

export const Header = () => (
  <header className={`${headerStyles.header} container-fullwidth`}>
    <Logo />
  </header>
)
