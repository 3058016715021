import React, { Fragment, ReactNode } from 'react'

import { Header } from '@components//organisms'

interface Props {
  children: ReactNode
}

export const Layout = ({ children }: Props) => (
  <Fragment>
    <Header />
    <main>{children}</main>
  </Fragment>
)
