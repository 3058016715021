import React from 'react'

interface Props {
  pageTitle: string
}

export const HeroSection = ({ pageTitle }: Props) => (
  <section className='container-constrained'>
    <h1>{pageTitle}</h1>
  </section>
)
