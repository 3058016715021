import React from 'react'

import { Layout } from '@components/layout'
import { HeroSection } from '@components/organisms/'

export const LandingPageTemplate = () => (
  <Layout>
    <HeroSection pageTitle='HOME PAGE' />
  </Layout>
)
